import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "@styles/pages/video.module.css";


const VideoPage = ({ data }) => (
  <Layout>
    <SEO title="Video" />
    <section className={styles.container} dangerouslySetInnerHTML={{__html: data.video.edges[0].node.html}}>
    </section>
  </Layout>
)

export default VideoPage

export const pageQuery = graphql`
  {
    video: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/video/"}}) {
      edges {
        node {
          html
        }
      }
    }
  
  }
`;
